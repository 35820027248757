<template>
    <section id="secHomeService">
        <v-img
            dark
            :src="require('@/assets/Backgrounds/bg-home-service.jpg')"
            class="text--white"
        >
            <v-container>
                <v-responsive>
                    <v-container>
                        <h1
                            class="text-uppercase text-center text-h6 text-md-h4 font-weight-black title-services"
                            data-aos="fade-down"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                            data-aos-offset="300"
                        >
                            {{ title }}
                        </h1>
                        <v-layout
                            d-flex justify-center
                            class="my-cont-service"
                        >
                            <v-flex
                                v-for="(item, i) in items"
                                :key="i"
                            >
                                <v-img
                                    :src= "item.img"
                                    width="50%"
                                    class="mx-auto"
                                    data-aos="flip-left"
                                    data-aos-duration="1500"
                                    data-aos-delay="100"
                                    data-aos-offset="300"
                                />
                                <h4
                                    class="text-center text-md-h5 text-subtitle-1 text-uppercase"
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-delay="100"
                                    data-aos-offset="300"
                                >{{ item.title }}</h4>
                            </v-flex>
                        </v-layout>
                        <v-spacer class="space-service" />
                        <v-container d-flex justify-center class="btn-viewMore">
                                <v-img
                                    :src="require('@/assets/img/view-more.png')"
                                    class="img-responsive text-center dflex align-center"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                    data-aos-delay="100"
                                    data-aos-offset="300"
                                >
                                    <router-link
                                        to="servicios"
                                        class="service-viewMore white--text text-md-subtitle-1 text-lg-h6 text-decoration-none"
                                    >
                                        Ver más
                                    </router-link>
                                </v-img>
                        </v-container>
                    </v-container>
                </v-responsive>
            </v-container>
        </v-img>
        <v-img
            dark
            :src="require('@/assets/Backgrounds/bg-home-service-text.jpg')"
            class="text--white home--service--text"
        >
            <v-container class="shape-bottom-text shape-bottom-xl-text">
                <h1 class="text-center display-1 mt-12 font-weight-black text-uppercase">
                    Nuestro proceso de fresado
                </h1>
                <v-row no-gutters>
                    <v-col
                    v-for="(item, i) in contents"
                    :key="i"
                    class=""
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                    data-aos-delay="100"
                    >
                    
                    <v-card
                        class="pt-15 pb-10 d-flex align-center justify-center flex-column"
                        outlined
                        tile
                        color="transparent"
                    >
                        <v-img :src="item.img" class="img"/>
                        <div class="height-txt">
                            <p class="pt-10 text-center title">{{ item.text }}</p>
                        </div>
                    </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-img>
    </section>
</template>

<script>
export default {
    name: 'SectionHomeServices',
    data() {
        return {
            title: 'Servicios',
            items: [
                {
                    img: require('@/assets/img/logo-services-fresado.png'),
                    title: 'Fresado'
                },
                {
                    img: require('@/assets/img/logo-services-microfresado.png'),
                    title: 'microfresado'
                }
            ],
            subtitle: 'Mediante la utilización de nuestras maquinas se obtiene un proceso ecológico y limpio que cuida al medio ambiente',
            contents: [
                {
                    id: 1,
                    img: require('@/assets/icons/bad-condition.svg'),
                    text: 'Vía en mal estado'
                },
                {
                    id: 2,
                    img: require('@/assets/icons/maintenance.svg'),
                    text: 'Vía en mantenimiento'
                },
                {
                    id: 3,
                    img: require('@/assets/icons/reuse.svg'),
                    text: 'Acopio de material fresado para reutilizar en la estructura de la vía'
                },
            ]
        }
    },
}
</script>

<style lang="scss">
#secHomeService {
    .my-cont-service { margin: 0 20vw; }
    .space-service { margin: 4vw 0; }
    .btn-viewMore {
        width: 15%;
        transition: transform .2s;
        &:hover {
            transform: scale(1.2);
        }
    }
    .title-services { margin-top: 2vw; margin-bottom: 4vw; }
    .shape-bottom-text {
        .bottom-text-service { padding: 9% 0 0 2%; }
    }

    .height-txt {
        height: 10vw;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
    }

    @media (min-width: 2304px) {
        .title-services { margin-bottom: 7vw; }
        .space-service-xl-text { margin: 13.5vw; }
        .shape-bottom-xl-text {
            h2.bottom-text-service { padding: 10% 0 0 2; }
        }
    }

    @media (max-width: 2303px) and (min-width: 1921px) {
        .title-services { margin-bottom: 6vw; }
        .shape-bottom-text {
           h2.bottom-text-service { padding: 12% 0 0; }
        }
    }
    @media (max-width: 1920px) and (min-width: 1681px) {
        .title-services { margin-top: 3vw; margin-bottom: 5vw;}
        /* .shape-bottom-text {
            h2.bottom-text-service { padding: 9% 8% 0; }
        } */
    }

    @media (max-width: 1680px) and  (min-width: 1441px) {
        .space-service-text { margin: 11vw 0; }
        .shape-bottom-text {
            .bottom-text-service { padding: 10% 0 0 0%; }
        }
    }

    @media (max-width: 1440px) and (min-width: 1367px) {
        .space-service-text { margin: 6vw 0; }
    }

    @media (max-width: 1366px) and (min-width: 1281px)  {
        .shape-bottom-text {
            .bottom-text-service { padding: 8% 0 0 2%; }
        }
    }

    @media (max-width: 1280px) and (min-width: 1023px) {
        .shape-bottom-text {
            .bottom-text-service { padding: 7% 4% 0; }
        }
    }

    @media (max-width: 1024px) and (min-width: 799px) {
        .shape-bottom-text {
            .bottom-text-service { padding: 5% 7% 0; }
        }
    }

    @media (max-width: 800px) and (min-width: 769px) {
        .shape-bottom-text {
            .bottom-text-service { padding: 5% 6% 0; }
        }
    }

    @media (max-width: 768px) and (min-width: 600px) {
        .shape-bottom-text {
            .bottom-text-service { padding: 0% 4% 0; }
        }
    }

    @media (max-width: 600px) {
        .shape-bottom-text {
            .bottom-text-service { padding: 3% 2% 0; }
        }
    }

    @media (min-width: 400px) and (max-width: 599px) {
        .btn-viewMore { width: 30%; }
        .space-service-text { margin: 0; }
    }

    @media (max-width: 400px) {
        .btn-viewMore { width: 50%; }
        .shape-bottom-text {
            &::before {
                bottom: 2%;
            }
        }
    }
}
</style>